@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Helvetica';
  src: url('./fonts/Helvetica.otf');
}
@font-face {
  font-family: 'Helvetica-light';
  src: url('./fonts/Helvetica.ttf');
}
@font-face {
  font-family: 'Helvetica-bold';
  src: url('./fonts/Helvetica-Bold.otf');
}

input {
  text-transform: uppercase;
}
textarea {
  text-transform: uppercase;
}

select {
  text-transform: uppercase;
}

.consulta {
  height: 36vh;
  margin-right: 20%;
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 13px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
select {
  -webkit-appearance: listbox !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
* {
  box-sizing: border-box;
}

.checkboxEspecial[type='checkbox']:before {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #808080;
  content: '';
  background: #fff;
}
.checkboxEspecialDeshabilitado[type='checkbox']:before {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #c8c6c6;
  content: '';
  background: #c8c6c6;
}
.checkboxEspecial[type='checkbox']:after {
  position: relative;
  display: block;
  left: 2px;
  width: 16px;
  height: 16px;
  content: '';
  top: -18px;
}

.checkboxEspecial.personalizado[type='checkbox']:checked:after {
  background-image: url('./imagenes/checkboxImagen.png'),
    linear-gradient(135deg, #b1b6be 0%, #fff 100%);
}
.checkboxEspecial.personalizado[type='checkbox']:not(
    :disabled
  ):checked:hover:after {
  background-image: url('./imagenes/checkboxImagen.png'),
    linear-gradient(135deg, #8bb0c2 0%, #fff 100%);
}
